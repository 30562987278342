$white: #ffffff;
$pd-color-light-gray: #eff3fa;
$pd-color-gray: #e5e5e5;
$pd-color-dark-gray: #777777;
$pd-color-darker-gray: #414040;
$pd-color-black: #18191f;
$pd-color-cerulean-blue: #0f68b8;
$pd-color-cyan-blue: #334756;

// color theme
$primary-color: #193766;
$secondary-color: #384253;
$accent-color: #d2aa44;
$button-color: $pd-color-cerulean-blue;
$secondary-footer: #041e59;
$main-footer: #083c85;
