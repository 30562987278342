.ant-layout-sider.pd-sidebar {
  bottom: 0;
  height: 100vh;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  border-right: 1px solid rgb(235, 237, 240);

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;

    ul.ant-menu {
      flex-grow: 1;
    }

    .menu-item,
    .logout {
      font-size: 16px;
      font-weight: bold;
    }

    .logout {
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      gap: 20px;
      margin: 20px 0;
      padding-left: 24px;
    }

    .logo {
      margin: 16px;
      width: 130px;

      img {
        width: 100%;
      }
    }
  }
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
