@import 'src/scss/variables/color';

.pd-text {
  &-primary {
    color: $primary-color;
  }

  &-secondary {
    color: $secondary-color;
  }

  &-accent {
    color: $accent-color;
  }
}
